<template>
  <section class="sub_sec">
    <NoticeDetailWrap :detailTit="dataTit" :detailDate="dataDate" :detailConts="dataConts" />
    <SubmitButton :btnObj="{ class: 'more', text: '목록보기' }" @btnEvt="goNotice" />
  </section>
</template>

<script>
import SubmitButton from "@/components/SubmitButton.vue"
import NoticeDetailWrap from "@/components/Notice/NoticeDetailWrap.vue"

export default {
  name: "NoticeDetail",
  components: {
    SubmitButton,
    NoticeDetailWrap,
  },
  data() {
    return {
      dataTit: "",
      dataDate: "",
      dataConts: "",
    }
  },
  mounted() {
    this.fetchNoticeDetail()
  },
  methods: {
    goNotice() {
      this.$router.push("/notice")
    },
    async fetchNoticeDetail() {
      const httpData = {}
      httpData.Header = {
        CmdType: "010003",
        CallApp: "www",
        TransactionID: this.$guid(),
      }
      httpData.Body = {
        Idx: Number(this.$route.params.idx),
      }

      try {
        const { data } = await this.$SRSServer(httpData)
        const sysDate = new Date(data.Body[0].RegDT)
        this.dataTit = data.Body[0].Title
        this.dataDate = this.$changeDateFormat(sysDate)
        this.dataConts = data.Body[0].Contents.replaceAll("\n", "<br>")
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>
