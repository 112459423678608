<template>
  <div class="detail_wrap">
    <h4>공지사항</h4>
    <div class="notice_tit">
      <strong class="title">{{ detailTit }}</strong>
      <span class="date">{{ detailDate.replaceAll("-", ".") }}</span>
    </div>
    <div class="detail_desc">
      <pre v-html="detailConts" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeDetailWrap",
  props: {
    detailTit: {
      type: String,
    },
    detailDate: {
      type: String,
    },
    detailConts: {
      type: String,
    },
  },
}
</script>
